var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-col",
    { staticClass: "tc btn-unified", attrs: { span: 24 } },
    [
      _vm._t("btn-before"),
      _vm.showCancel
        ? _c(
            "el-button",
            { attrs: { loading: _vm.loading }, on: { click: _vm.cancel } },
            [_vm._v(" " + _vm._s(_vm.cancelTitle) + " ")]
          )
        : _vm._e(),
      _vm._t("btn-center"),
      _vm.showSubmit
        ? _c(
            "debounce-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.submit }
            },
            [_vm._v(" " + _vm._s(_vm.submitTitle) + " ")]
          )
        : _vm._e(),
      _vm._t("btn-behind")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }