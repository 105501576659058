<!-- search-inner -->
<template>
  <div class="search-inner">
    <el-form :label-position="labelPosition" :label-width="labelWidth" :class="{ 'has-label-width': labelWidth }" :label-suffix="labelSuffix" :model="searchForm" @submit.native.prevent="search">
      <div class="search-hd" :class="{'search-hd-input':foldBtn}">
        <div class="search-input">
          <slot name="input-inner">
            <el-input v-if="foldBtn" v-model="searchForm.obscure" prefix-icon="el-icon-search" :placeholder="basePlaceholder" :disabled="foldSearch" />
            <p v-else class="title">
              {{ title }}
            </p>
          </slot>
        </div>
        <div class="btn-inner">
          <slot name="btn-inner">
            <el-button v-if="clearBtn !== false" class="reset-btn" @click="reset">
              {{ clearBtn }}
            </el-button>
            <el-button v-if="searchBtn !== false" native-type="submit" type="primary">
              {{ searchBtn }}
            </el-button>
            <el-button v-if="moreBtn !== false" type="primary" class="moreBtn" @click="foldClick">
              {{ moreBtn }}<i class="el-icon-caret-top" :class="[foldSearch ? 'icon-transform' : 'icon-transform90']" />
            </el-button>
          </slot>
        </div>
      </div>
      <div class="search-bd">
        <el-row ref="searchBd" :gutter="16">
          <slot :searchForm="searchForm" />
        </el-row>
      </div>
    </el-form>
  </div>
</template>
<script>
export default {
  name: 'IcsSearchInner',
  components: {
  },
  props: {
    moreSearch: {
      type: Boolean,
      default: false
    },
    foldBtn: {
      type: [String, Boolean],
      default: false
    },
    searchId: {
      type: [Number, String],
      default: ''
    },
    title: {
      type: String,
      default: '条件筛选'
    },
    searchBtn: {
      type: [String, Boolean],
      default: '搜索'
    },
    clearBtn: {
      type: [String, Boolean],
      default: '清空'
    },
    moreBtn: {
      type: [String, Boolean],
      default: false
    },
    labelPosition: {
      type: String,
      default: 'top'
    },
    labelWidth: {
      type: [String, Number],
      default: ''
    },
    labelSuffix: {
      type: String,
      default: '：'
    },
    searchForm: {
      type: Object,
      default () {
        return {}
      }
    },
    foldSearch: {
      type: Boolean,
      default: true
    },
    basePlaceholder: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
    }
  },
  watch: {
  },
  created () {
  },
  methods: {
    search () {
      this.$emit('submit-search', { searchId: this.searchId, searchForm: this.searchForm })
      return false
    },
    reset () {
      this.$emit('clear-search', this.searchId)
    },
    foldClick () {
      this.$emit('update:foldSearch', !this.foldSearch)
      this.$emit('advanced-search', { searchId: this.searchId, searchForm: this.searchForm })
    }
  }
}
</script>
<style>
</style>
