<!-- table-inner -->
<template>
  <div class="form-area">
    <div v-if="!showHeader" class="area-title">
      <p class="title">
        {{ title }}
      </p>
      <slot name="btn-inner" />
    </div>
    <div :class="viewEdit() ? 'form-inner' : 'detail-inner'">
      <slot name="special-inner" />
      <el-row :gutter="80">
        <slot />
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  name: 'IcsPageInner',
  components: {
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    showHeader: {
      type: Boolean,
      default: false
    },
    viewProp: {
      type: Function,
      default: null
    }
  },
  data () {
    return {
    }
  },
  watch: {
  },
  created () {
  },
  methods: {
    viewEdit () {
      if (this.viewProp) {
        return this.viewProp()
      } else {
        return ['add', 'edit'].includes(this.$route.params.editMode)
      }
    }
  }
}
</script>
<style>
</style>
