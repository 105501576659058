var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.viewEdit()
        ? _vm._t("default")
        : _vm._t("detail", function() {
            return [
              !_vm._.isEmpty(_vm.hrefProp)
                ? _c(
                    "router-link",
                    {
                      staticClass: "item-p",
                      attrs: { tag: "a", target: "_blank", to: _vm.hrefProp }
                    },
                    [_vm._v(" " + _vm._s(_vm.format(_vm.prop)) + " ")]
                  )
                : _c("p", { staticClass: "item-p" }, [
                    _vm._v(" " + _vm._s(_vm.format(_vm.prop)) + " ")
                  ])
            ]
          })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }