<!-- table-inner -->
<template>
  <div class="table-inner" :class="{'table-border-inner': border}">
    <div v-if="tableHeader" class="table-hd">
      <p class="title">
        <slot name="title">
          {{ title }}
        </slot>
      </p>
      <div class="btn-inner">
        <slot name="btn-inner" />
        <el-popover v-if="!hideSetColumns" class="set-column-btn" placement="bottom" width="200" trigger="hover">
          <el-checkbox-group v-model="checkColumnList" class="column-checkbox-group" @change="changeCheckColumn">
            <el-checkbox v-for="item in _.filter(columnOption, function(o) { return o.canSet !== false })" :key="item.prop" :label="item.prop">
              {{ item.label }}
            </el-checkbox>
          </el-checkbox-group>
          <el-button v-if="!hideColumnBtn" slot="reference">
            显示/隐藏列
          </el-button>
        </el-popover>
        <el-button v-if="!hideExportBtn" @click="exportFile">
          导出
        </el-button>
      </div>
    </div>
    <div class="table-bd">
      <slot name="table-bd">
        <el-table ref="table" :border="border" v-bind="$attrs" :data="tableData" v-on="$listeners">
          <slot name="table-columns-before" />
          <el-table-column v-for="item in columnList" :key="item.prop" v-bind="item" show-overflow-tooltip>
            <template v-if="item.hrefProp" v-slot="slotProps">
              <router-link v-if="!_.isEmpty(item.hrefProp(slotProps.row))" tag="a" target="_blank" :to="item.hrefProp(slotProps.row)">
                {{ utils.isEffectiveCommon(slotProps.row[item.prop]) }}
              </router-link>
              <span v-else>
                {{ utils.isEffectiveCommon(slotProps.row[item.prop]) }}
              </span>
            </template>
          </el-table-column>
          <slot name="table-columns-after" />
        </el-table>
      </slot>
    </div>
    <div v-if="tableFooter" class="table-ft">
      <slot name="table-ft">
        <pagination :total="total" :page-num="pageNum" :page-size="pageSize" :pages="pages" :page-id="pageId" :page-sizes="pageSizes" :layout="layout" @changePageNum="changePageNum" @changePageSize="changePageSize" />
      </slot>
    </div>
  </div>
</template>
<script>
import localforage from 'localforage'
import hash from 'object-hash'
const tableStore = localforage.createInstance({
  storeName: 'tableColumnSet'
})
export default {
  name: 'IcsTableInner',
  components: {
  },
  props: {
    // 表格列配置
    columnOption: {
      type: Array,
      default () {
        return []
      }
    },
    // 隐藏切换列按钮
    hideSetColumns: {
      type: Boolean,
      default: false
    },
    hideColumnBtn: {
      type: Boolean,
      default: false
    },
    // 隐藏导出按钮
    hideExportBtn: {
      type: Boolean,
      default: false
    },
    // 是否显示表头
    tableHeader: {
      type: Boolean,
      default: true
    },
    // 表格ID
    tableId: {
      type: [Number, String],
      default: ''
    },
    // 是否显示底部分页器
    tableFooter: {
      type: Boolean,
      default: true
    },
    // 是否显示边线
    border: {
      type: Boolean,
      default: false
    },
    // 表格标题
    title: {
      type: String,
      default: '搜索结果'
    },
    // 数据源
    tableData: {
      type: Array,
      default () {
        return []
      }
    },
    // 分页器条目总数
    total: {
      type: Number,
      default: undefined
    },
    // 分页器当前页数
    pageNum: {
      type: Number,
      default: undefined
    },
    // 分页器每页条数
    pageSize: {
      type: Number,
      default: undefined
    },
    // 分页器总页数
    pages: {
      type: Number,
      default: undefined
    },
    // 分页器ID
    pageId: {
      type: [String, Number],
      default: ''
    },
    // 分页器每页条数配置
    pageSizes: {
      type: Array,
      default: undefined
    },
    // 分页器功能配置
    layout: {
      type: String,
      default: undefined
    },
    activeLayout: {
      type: String,
      default: undefined
    },
    openLayout: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      columnList: [],
      checkColumnList: [],
      nowVersion: '',
      tableSetKey: this.$route.path + (this.tableId ? '/' + this.tableId : '')
    }
  },
  watch: {
  },
  created () {
    this.addLayoutWatch()
    this.initColumn()
  },
  methods: {
    async initColumn () {
      if (this.columnOption.length <= 0) {
        return false
      }
      this.setVersion()
      let checkList = []
      const saveData = await tableStore.getItem(this.tableSetKey)
      if (saveData && saveData.version === this.nowVersion) {
        checkList = saveData.checkedValue || []
      } else {
        await tableStore.removeItem(this.tableSetKey)
        // 初始化默认选项
        this.columnOption.forEach(item => {
          if (item.canSet !== false) {
            checkList.push(item.prop)
          }
        })
      }
      this.checkColumnList = checkList
      this.changeCheckColumn(checkList)
    },
    changeCheckColumn (checkedValue) {
      const checkColumn = []
      this.columnOption.forEach(item => {
        if (item.canSet === false || checkedValue.includes(item.prop)) {
          checkColumn.push(item)
        }
      })
      this.columnList = checkColumn
      this.saveColumnData(checkedValue)
      this.$emit('changeCheckColumn', checkedValue)
    },
    setVersion () {
      const props = []
      this.columnOption.forEach(item => {
        props.push(item.prop)
      })
      this.nowVersion = hash(props)
    },
    saveColumnData (checkedValue) {
      tableStore.setItem(this.tableSetKey, { version: this.nowVersion, checkedValue })
    },
    addLayoutWatch () {
      if (this.activeLayout) {
        this.$watch('activeLayout', () => { this.doLayout() })
      } else {
        if (this.openLayout) {
          this.$watch('$parent.active', () => { this.doLayout() })
        }
      }
    },
    doLayout () {
      this.$nextTick(() => {
        if (this.$refs.table) {
          this.$refs.table.doLayout()
        }
      })
    },
    clearSelection () {
      this.$refs.table.clearSelection()
    },
    changePageNum (pageInfo) {
      this.$emit('changePageNum', pageInfo)
    },
    changePageSize (pageInfo) {
      this.$emit('changePageSize', pageInfo)
    },
    exportFile () {
      const fieldNames = []
      this.columnList.forEach(item => {
        fieldNames.push(item.prop)
      })
      this.$emit('exportFile', { fieldNames: fieldNames.join(',') })
    }
  }
}
</script>
<style lang="less">
.column-checkbox-group {
  .el-checkbox {
    display: block;
    margin-right: 0;
    margin-bottom: 5px;
  }
}
.set-column-btn {
  margin-right: 7px;
  margin-left: 7px;
}
</style>
