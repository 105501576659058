<!-- table-inner -->
<template>
  <div>
    <slot v-if="viewEdit()" />
    <slot v-else name="detail">
      <router-link v-if="!_.isEmpty(hrefProp)" tag="a" target="_blank" :to="hrefProp" class="item-p">
        {{ format(prop) }}
      </router-link>
      <p v-else class="item-p">
        {{ format(prop) }}
      </p>
    </slot>
  </div>
</template>
<script>
export default {
  name: 'IcsItemInner',
  components: {
  },
  props: {
    prop: {
      type: [String, Number, Array],
      default: ''
    },
    format: {
      type: Function,
      default: null
    },
    viewProp: {
      type: Function,
      default: null
    },
    hrefProp: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
    }
  },
  watch: {
  },
  created () {
  },
  methods: {
    viewEdit () {
      if (this.viewProp) {
        return this.viewProp()
      } else {
        return ['add', 'edit', 'agency'].includes(this.$route.params.editMode)
      }
    }
  }
}
</script>
<style>
</style>
